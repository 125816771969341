<template>
<div id="MHSneakers" v-lazy-container="{ selector: 'img' }">
	
	<vue-headful title="China 2024 – Morten Hauge"/>
	
	<section class="wrapper wrapper-darkest">
		
		<img :data-src="images[0].url" class="hero-img-case-study" :alt="images[0].alt">

		<section class="content-case-study">
			
			<!-- <img :data-src="images[15].url" class="img-full" :alt="images[15].alt"> -->

			<h1>China 2024</h1>
			
			<p class="intro_text">
		
				In May of 2024 I visited China for the first time. And the new, excotic and unfamiliar environment got me really back into
				photography. I don't really post photography on social media, so I crafted this gallery website to share my experiences. 

				<br><br>

				All photography, design and front-end development done by me.
		
			</p>
		
			<div class="button-container">
				<a href="https://morten-hauge.com/china2024" target="_blank"><span class="button">Check out live website</span></a>
			</div>

			<section class="grid2">

				<figure v-scrollanimation2>
					<img :data-src="images[1].url" :alt="images[1].alt">
					<figcaption :class="images[1].captionClass">{{images[1].caption}}</figcaption>
				</figure>
				
				<figure v-scrollanimation2>
					<img :data-src="images[2].url" :alt="images[2].alt">
					<figcaption :class="images[2].captionClass">{{images[2].caption}}</figcaption>
				</figure>

			</section>

			<section class="grid3">

				<figure v-scrollanimation2>
					<img :data-src="images[14].url" :alt="images[14].alt">
					<figcaption :class="images[14].captionClass">{{images[14].caption}}</figcaption>
				</figure>

				<figure v-scrollanimation2>
					<img :data-src="images[16].url" :alt="images[16].alt">
					<figcaption :class="images[16].captionClass">{{images[16].caption}}</figcaption>
				</figure>

				<figure v-scrollanimation2>
					<img :data-src="images[17].url" :alt="images[17].alt">
					<figcaption :class="images[17].captionClass">{{images[17].caption}}</figcaption>
				</figure>

			</section>

		</section>
	
	</section>

	<section class="wrapper wrapper-dark">
		
		<section class="content-case-study" v-scrollanimation>

			<div class="text">

				<h2 class="learned">Animating with Intersection Observerer API</h2>

				<div class="grid2">

					<p>
						
						I wanted this web gallery to feel a bit alive with some nice entry animation of the images here and there. I have some experience using the GSAP ScrollTrigger library for this purpose from the past. 
						But after some quick digging I came across the Intersection Observer API in JavaScript. I got really excited to try to achive these animations without a third party library.

						<br><br>

						With a few lines of JavaScript I set up listeners for specific classes of the images I wanted to animate in. As a default they all have opacity set to 0 and a transition added. 
						When the images are scrolled into the viewport the Intersection Observer adds a class with opacity set to 1. I also added some blur and position offset for some of them. And voila! 
						The photos have cool entry animations.
						
						<br><br>

						In some cases I also added transition delays to achive a nice staggered animation with multiple images.

					</p>

					<figure v-scrollanimation2>
					<!-- <img :data-src="images[21].url" :alt="images[21].alt"> -->
					<!-- <video :data-src="images[23].url" width="500" height="400"></video> -->
					<video src="../assets/work/china2024/intersection_observer.mp4" poster="../assets/work/china2024/intersection_observer_thumb.jpg" controls loop></video>
					<figcaption :class="images[21].captionClass">{{images[21].caption}}</figcaption>
				</figure>

				</div>
		
			</div>
		
		</section>
	
	</section>	
	
	<section class="wrapper wrapper-darkest">
		
		<section class="content-case-study" v-scrollanimation>

			<div class="text">

				<h2 class="learned">Animating with Rive</h2>

				<div class="grid2">

					<p>
						
						I also wanted to sprinkle the page with a couple of more animated elements. I've been curious about both Rive and Lottielab for web animations for a little while.
						After some further digging I landed on Rive for this project. 

						<br><br>

						Rive brought back memories of the good old days of Flash. It was really cool to make animations for the web again.
						And the interface and how things worked kind of reminded me of After Effects – a program I've spent a good amount of hours in the past year. 
						Both the Rive Editor itself and how to implement the animations in my code was suprisingly straight forward. 
						
						<br><br>

						I ended up doing some animated image transitions for the main hero element and an animated location indicator on a map for each destination.

					</p>

					<figure v-scrollanimation2>
					<!-- <img :data-src="images[21].url" :alt="images[21].alt"> -->
					<video src="../assets/work/china2024/rive.mp4" poster="../assets/work/china2024/rive_thumb.jpg" controls loop></video>
					<figcaption :class="images[21].captionClass">Hero elements animated with Rive</figcaption>
				</figure>

				</div>
		
			</div>
		
		</section>
	
	</section>

	<section class="wrapper wrapper-dark">
		
		<section class="content-case-study" v-scrollanimation>

			<h2>Conclusion</h2>
			
			<p>
				
				Another project where I learned a couple of new tricks – nice! I'll definitely continue using the Intersection Observer API, and I'll for sure make more web animations with Rive.

				<br><br>

				I also think the whole project came togheter pretty nice, and I'm proud of the way I present my photographs from an awesome trip.

			</p>

		</section>
	
	</section>
	
</div>
</template>

<script>


export default
{
	components: 
	{
		
	},
	data() {
		return{
			images:
			[
				{ url: require('../assets/work/china2024/china_hero_v1.jpg'), alt: 'China hero desktop'},

				{ url: require('../assets/work/china2024/china_2024_desktop_001.jpg'), alt: 'Desktop hero section for destination – Xiamen', caption: 'Desktop hero section for destination – Xiamen', captionClass: 'caption-left'},
				{ url: require('../assets/work/china2024/china_2024_desktop_002.jpg'), alt: 'Two column photo layout', caption: 'Two column photo layout', captionClass: 'caption-left'},
				{ url: require('../assets/work/china2024/china_2024_desktop_003.jpg'), alt: '', caption: 'Hero section mobile', captionClass: 'caption-left',},
				{ url: require('../assets/work/china2024/china_2024_desktop_004.jpg'), alt: '', caption: '', captionClass: 'caption-left'},
				{ url: require('../assets/work/china2024/china_2024_desktop_005.jpg'), alt: '', caption: '', captionClass: 'caption-left'},
				{ url: require('../assets/work/china2024/china_2024_desktop_006.jpg'), alt: '', caption: '', captionClass: 'caption-left'},
				{ url: require('../assets/work/china2024/china_2024_desktop_007.jpg'), alt: '', caption: '', captionClass: 'caption-left'},
				{ url: require('../assets/work/china2024/china_2024_desktop_008.jpg'), alt: '', caption: '', captionClass: 'caption-left'},
				{ url: require('../assets/work/china2024/china_2024_desktop_009.jpg'), alt: '', caption: '', captionClass: 'caption-left'},
				{ url: require('../assets/work/china2024/china_2024_desktop_010.jpg'), alt: '', caption: '', captionClass: 'caption-left'},
				{ url: require('../assets/work/china2024/china_2024_desktop_011.jpg'), alt: '', caption: '', captionClass: 'caption-left'},
				{ url: require('../assets/work/china2024/china_2024_desktop_012.jpg'), alt: '', caption: '', captionClass: 'caption-left'},
				{ url: require('../assets/work/china2024/china_2024_desktop_013.jpg'), alt: '', caption: '', captionClass: 'caption-left'},

				{ url: require('../assets/work/china2024/china_2024_mobile_001.png'), alt: 'Main hero mobile', caption: 'Main hero mobile', captionClass: 'caption-left'},
				{ url: require('../assets/work/china2024/china_2024_mobile_002.png'), alt: '', caption: '', captionClass: 'caption-left'},
				{ url: require('../assets/work/china2024/china_2024_mobile_003.png'), alt: 'Single column photo layout mobile', caption: 'Single column photo layout mobile', captionClass: 'caption-left'},
				{ url: require('../assets/work/china2024/china_2024_mobile_004.png'), alt: 'Single column photo layout mobile', caption: 'Single column photo layout mobile', captionClass: 'caption-left'},
				{ url: require('../assets/work/china2024/china_2024_mobile_005.png'), alt: '', caption: '', captionClass: 'caption-left'},
				{ url: require('../assets/work/china2024/china_2024_mobile_006.png'), alt: '', caption: '', captionClass: 'caption-left'},
				{ url: require('../assets/work/china2024/china_2024_mobile_007.png'), alt: '', caption: '', captionClass: 'caption-left'},

				{ url: require('../assets/work/china2024/intersection_observer.gif'), alt: '', caption: 'Staggered entry animation', captionClass: 'caption-left'},

				{ url: require('../assets/work/china2024/intersection_observer.mp4'), alt: '', caption: '', captionClass: 'caption-left'},
				{ url: require('../assets/work/china2024/china_2024_mobile_007.png'), alt: '', caption: '', captionClass: 'caption-left'}
			]	
		}
	}	
}
	
</script>